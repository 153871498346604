import { experimentalStyled as styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
import Zoom from "@mui/material/Zoom";
import Avatar from "@mui/material/Avatar";

import personalWorkData from "./personal-projects.json";
import "./personal-projects.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const PersonalProjects = () => {
  const projects = personalWorkData.projects;

  const openProjectUrl = (projectIndex: number) => {
    const newWindow = window.open(
      projects[projectIndex].url,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };
  return (
    <>
      <div id="personal_projects" className="personal_projects_title">
        <h2 className="medium_font">{personalWorkData.title}</h2>
      </div>

      <div className="personal_projects_container">
        {projects.map((projectObj, i) => (
          <Item
            key={projectObj.url + i}
            sx={{ bgcolor: "#eee", cursor: "pointer" }}
            className="personal_project_item"
            onClick={() => {
              openProjectUrl(i);
            }}
          >
            <BootstrapTooltip
              TransitionComponent={Zoom}
              title={projectObj.name}
              arrow
              placement="top"
            >
              <Avatar
                alt={projectObj.name}
                variant="rounded"
                sx={{ width: 56, height: 56, bgcolor: projectObj.color }}
                className="personal_project_avatar"
              >
                <Icon fontSize="large">{projectObj.icon}</Icon>
              </Avatar>
            </BootstrapTooltip>
          </Item>
        ))}
      </div>
    </>
  );
};

export default PersonalProjects;
