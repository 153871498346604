import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { pink } from "@mui/material/colors";
import Zoom from "@mui/material/Zoom";

import Icon from "@mui/material/Icon";

import footerData from "./Footer.json";

const Copyright = () => {
  return footerData?.footer?.copyright ? (
    <Typography variant="body2" color="text.secondary" align="center">
      {footerData.footer.copyright}
    </Typography>
  ) : null;
};

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{ bgcolor: "background.paper", py: 6, marginTop: "2rem" }}
    >
      <Container maxWidth="lg">
        <Typography variant="h6" align="center" gutterBottom>
          {footerData?.footer?.title}
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="text.secondary"
          component="span"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "IBM Plex Serif",
            fontWeight: "700",
            fontStyle: "italic",
            textShadow: "0 0 black",
          }}
        >
          Made in
          <Box
            sx={{
              display: "flex",
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
              alignSelf: "flex-end",
            }}
            className="love_icon_container"
          >
            <Zoom in={true}>
              <Icon sx={{ color: pink[500] }}>favorite</Icon>
            </Zoom>
          </Box>
          with React 18
        </Typography>
        <Copyright />
      </Container>
    </Box>
  );
};

export default Footer;
