import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

interface HeaderProps {
  sections: ReadonlyArray<{
    title: string;
    href: string;
  }>;
}

const Header = ({ sections }: HeaderProps) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" sx={{ backgroundColor: "#474559" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* Icon of UV */}
            <Avatar
              alt="Yuvraj Patil Personal Website"
              src="logo512.png"
              onClick={goToTop}
              sx={{
                display: { xs: "none", md: "flex" },
                cursor: "pointer",
                mr: 1,
              }}
            />

            {/* Menu for laptops */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "space-evenly",
              }}
            >
              {sections.map((section) => (
                <Button
                  key={section.title + section.href}
                  href={section.href}
                  onClick={handleCloseNavMenu}
                  variant={"outlined"}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    fontSize: "1rem",
                  }}
                >
                  {section.title}
                </Button>
              ))}
            </Box>

            {/* Menu for mobile devices */}
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {sections.map((section) => (
                  <MenuItem
                    key={section.title + section.href}
                    onClick={handleCloseNavMenu}
                  >
                    <a href={section.href}>
                      <Typography textAlign="center">
                        {section.title}
                      </Typography>
                    </a>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {/* Yuvraj Photo */}
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Yuvraj Patil">
                <IconButton onClick={goToTop} sx={{ p: 0 }}>
                  <Avatar alt="Yuvraj Patil" src="assets/yuvraj-patil.jpg" />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;
