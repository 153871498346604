import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";

import componentData from "./timeline.json";
import "./timeline.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const Timeline = () => {
  let companies = componentData.companies;

  let schools = componentData.schools;
  return (
    <>
      <Grid
        xs={12}
        sm={6}
        md={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Item>
          <div id="about" className="experience_section medium_font">
            <h2 className="timeline_title">Experience</h2>

            <div className="experienceSection">
              {companies?.map((companyObj, i) => (
                <div className="timeline-wrapper" key={companyObj.name + i}>
                  <div className="timeline-yr">
                    <span>{companyObj.year}</span>
                  </div>
                  <div className="timeline-info">
                    <h3>
                      <span className="position">{companyObj.position}</span>
                      <span className="milestone-subtitle">
                        <a
                          target="_blank"
                          href={companyObj.href}
                          rel="noopener noreferrer"
                        >
                          {companyObj.name}
                        </a>
                      </span>
                    </h3>
                    <ul className="roles">
                      {companyObj?.roles?.map((roleObj, j) => (
                        <li key={roleObj + j}>{roleObj}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Item>
      </Grid>
      <Grid
        xs={12}
        sm={6}
        md={6}
        sx={{
          justifyContent: { xs: "center", md: "left" },
        }}
        display="flex"
      >
        <Item>
          <div className="education_section medium_font">
            <h2 className="timeline_title">Education</h2>

            <div className="timeline education">
              {schools.map((schoolObj, i) => (
                <div className="timeline-wrapper" key={schoolObj.name + i}>
                  <div className="timeline-yr">
                    <span>{schoolObj.year}</span>
                  </div>
                  <div className="timeline-info">
                    <h3>
                      <span className="degree">{schoolObj.degree}</span>
                      <span className="milestone-subtitle-2">
                        <a
                          target="_blank"
                          href={schoolObj.href}
                          rel="noopener noreferrer"
                        >
                          {schoolObj.name}
                        </a>
                      </span>
                    </h3>
                    <ul className="roles">
                      {schoolObj?.roles?.map((rolesObj, j) => (
                        <li key={rolesObj + j}>{rolesObj}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Item>
      </Grid>
    </>
  );
};
export default Timeline;
