import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useInView } from "react-intersection-observer";

import componentData from "./skills.json";
import "./skills.css";
import SkillItem from "./SkillItem";
import useCheckMobileView from "../clubhouse/useCheckMobileView";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Skills = () => {
  const { ref, inView } = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  const isMobile = useCheckMobileView();

  let skills = componentData.skills;

  let gaugeProps1 = {
    id: "gauge1",
    width: 100,
    height: 100,
    value: skills[0].value,
    waveFrequency: 3,
    waveAmplitude: 3,
    waveAnimation: true,
    riseAnimation: true,
    circleStyle: {
      fill: skills[0].fillColor,
    },
    waveStyle: {
      fill: skills[0].fillColor,
    },
    textStyle: {
      fill: "black",
    },
    waveTextStyle: {
      fill: "black",
    },
    style: {
      display: "inline-block",
      margin: "5% 5% 0 5%",
    },
  };

  let gauges: any = [];
  gauges.push(gaugeProps1);
  for (var i = 1; i < skills.length; i++) {
    gauges.push(JSON.parse(JSON.stringify(gaugeProps1)));
    gauges[i].value = skills[i].value;
    gauges[i].circleStyle.fill = skills[i].fillColor;
    gauges[i].waveStyle.fill = skills[i].fillColor;
    gauges[i].id = "gauge" + (i + 1);
  }

  return (
    <>
      <div id="skills" className="skills_title">
        <h2 className="medium_font">Technical Skills</h2>
      </div>

      <div className="skills_container" ref={ref}>
        {skills.map((skillsObj, i) => (
          <Item key={skillsObj.name + i} sx={{ flexGrow: 1 }} className="skill">
            <SkillItem
              {...{
                isVisible: inView || isMobile,
                skill: skillsObj,
                gauge: gauges[i],
                index: { i },
              }}
            ></SkillItem>
          </Item>
        ))}
      </div>
    </>
  );
};
export default Skills;
