import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";

import componentData from "./achievements.json";
import "./achievements.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Achievements = () => {
  let achievementList = componentData.achievements;
  return (
    <div id="achievements" className="achievements_container">
      <h2 className="achievements_title medium_font">Achievements</h2>
      <Grid
        container
        mdOffset={0.5}
        xsOffset={0.6}
        sx={{ flexGrow: 1 }}
        rowSpacing={5}
      >
        {achievementList.map((achievementObj, i) => (
          <Grid xs={12} sm={4} md={4} key={achievementObj.date + i}>
            <Item
              key={achievementObj.date + i}
              sx={{ flexGrow: 1 }}
              className="achievement"
            >
              <button className={"medium_font type" + (i % 4)}>
                <h2>{achievementObj.title}</h2>
                <h3>{achievementObj.issuer}</h3>
                {/* <p>{achievementObj.description}</p> */}
                <p>{achievementObj.date}</p>
              </button>
            </Item>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
export default Achievements;
