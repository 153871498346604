import Box from "@mui/material/Box";
import Zoom from "@mui/material/Zoom";

// @ts-ignore
import LiquidFillGauge from "react-liquid-gauge";

import "./skills.css";

const SkillItem = ({ skill, gauge, index, isVisible }: any) => {
  return (
    <Box>
      <Zoom
        in={isVisible}
        style={{ transitionDelay: isVisible ? "200ms" : "0ms" }}
      >
        <div
          className={
            "gaugeSeperator inlineElement liguidGaugeContainer " +
            (!isVisible ? "hide" : "")
          }
          key={index}
        >
          <>
            <LiquidFillGauge {...gauge} key={index} />
            <span className="medium-font gaugeTitle text_center">
              <strong>{skill.name}</strong>
            </span>
          </>
        </div>
      </Zoom>
    </Box>
  );
};

export default SkillItem;
