import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import { CardActionArea } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Zoom from "@mui/material/Zoom";
import { useInView } from "react-intersection-observer";

import componentData from "./company-projects.json";
import "./company-projects.css";
import UVModal from "../../../components/UVModal";
import { getRefinedProjects } from "./companyProjectHelper";
import useCheckMobileView from "../../clubhouse/useCheckMobileView";

/**
 Notes: To hide project, use "disabled": true in project.json
 */

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const CompanyProjects = () => {
  const { ref, inView } = useInView({
    threshold: 1,
    triggerOnce: true,
  });
  const isMobile = useCheckMobileView();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedProject, setSelectedProject] = useState([] as any);

  let title = componentData.title,
    projects = getRefinedProjects(
      componentData.technologies,
      componentData.projects
    );

  const showProjectDetails = (projectId: number) => {
    setOpen(true);
    setSelectedProject(projects[projectId]);
  };

  const openProjectUrl = () => {
    const newWindow = window.open(
      selectedProject.url,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <div id="projects" className="projects_header_container" ref={ref}>
        <h2 className="medium_font">{title}</h2>
      </div>

      <Grid
        container
        spacing={{ xs: 2, md: 1 }}
        sx={{ flexGrow: 1 }}
        rowSpacing={2}
      >
        {(inView || isMobile) &&
          projects.map((projectObj: any, i: number) => (
            <Grid xs={12} sm={4} md={4} key={projectObj.title + i}>
              <Item key={projectObj.title + i} sx={{ flexGrow: 1 }}>
                <Zoom
                  in={inView || isMobile}
                  style={{
                    transitionDelay: inView || isMobile ? "500ms" : "0ms",
                  }}
                >
                  <Card
                    sx={{
                      background: "lightskyblue",
                      borderRadius: "10%",
                    }}
                    variant="outlined"
                    className="project_card medium_font"
                  >
                    <CardActionArea
                      onClick={() => {
                        showProjectDetails(i);
                      }}
                    >
                      <CardHeader
                        avatar={
                          <Avatar
                            alt={projectObj.title}
                            src={projectObj.logoUrl}
                            sx={{ width: 192, height: 192 }}
                            className="avatar"
                          />
                        }
                        className="card_header"
                      />
                      <CardContent>
                        <Typography
                          variant="h5"
                          component="div"
                          sx={{ fontFamily: "IBM Plex Serif" }}
                          className="project_title"
                        >
                          {projectObj.title}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Zoom>
              </Item>
            </Grid>
          ))}
      </Grid>

      <UVModal
        modalTitle={selectedProject.title}
        leftAvatars={selectedProject.technologies}
        roles={selectedProject.roles}
        disclaimer={selectedProject.disclaimer}
        url={selectedProject.url}
        open={open}
        handleClose={handleClose}
        openUrl={openProjectUrl}
      ></UVModal>
    </>
  );
};
export default CompanyProjects;
