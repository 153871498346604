const getRefinedTechnologies = (
  technologiesSrc: any,
  technologiesToSearch: string[]
) => {
  let result = JSON.parse(JSON.stringify(technologiesToSearch));
  result = result.map((technologyKey: string) => {
    return technologiesSrc[technologyKey];
  });
  return result;
};

const getRefinedProjects = (technologiesSrc: any, projectData: any[]) => {
  let result = JSON.parse(JSON.stringify(projectData));

  result = result.filter((projectObj: any) => !projectObj.disabled);

  result.forEach((projectObj: any) => {
    projectObj.technologies = getRefinedTechnologies(
      technologiesSrc,
      projectObj.technologies
    );
  });

  return result;
};

export { getRefinedTechnologies, getRefinedProjects };
