import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import {
  createTheme,
  Shadows,
  ThemeOptions,
  ThemeProvider,
} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";

import "./App.css";
import Header from "./components/Header";
import Home from "./modules/home/Home";
import Footer from "./components/Footer";
import appConfig from "./appConfig.json";
import Timeline from "./modules/timeline/Timeline";
import Achievements from "./modules/achievements/Achievements";
import Skills from "./modules/skills/Skills";
import CompanyProjects from "./modules/projects/company-work/CompanyProjects";
import PersonalProjects from "./modules/projects/personal-work/PersonalProjects";
import Contact from "./modules/contact/Contact";

const defaultTheme = createTheme();
// get the default `shadows` object
const defaultShadows: ThemeOptions["shadows"] = [...defaultTheme.shadows];

// disable shadows
const customTheme = createTheme({
  shadows: defaultShadows.map(() => "none") as Shadows,
  typography: {
    button: {
      textTransform: "capitalize",
    },
  },
});

function App() {
  const appSections = appConfig.headerLinks;

  return (
    <div className="App">
      <ThemeProvider theme={customTheme}>
        <CssBaseline />

        <Container maxWidth="lg">
          <main>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                minHeight={360}
              >
                <Header sections={appSections} />
                <Home></Home>
                <Timeline></Timeline>
                <Achievements></Achievements>
                <Skills></Skills>
                <CompanyProjects></CompanyProjects>
                <PersonalProjects></PersonalProjects>
                <Contact></Contact>
              </Grid>
            </Box>
          </main>
        </Container>
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
