import { experimentalStyled as styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Zoom from "@mui/material/Zoom";

import componentData from "./contact.json";
import "./contact.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const Contact = () => {
  const openProjectUrl = (projectIndex: number) => {
    const newWindow = window.open(
      componentData?.contacts?.[projectIndex].url,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <div id="contact" className="contact_title">
        <h2 className="medium_font">{componentData.title}</h2>
      </div>

      <div className="contact_container">
        {componentData?.contacts?.map((contactObj: any, i) => (
          <Item
            key={contactObj.url + i}
            sx={{ bgcolor: "#474559", cursor: "pointer" }}
            className="contact_item"
            onClick={() => {
              openProjectUrl(i);
            }}
          >
            <BootstrapTooltip
              TransitionComponent={Zoom}
              title={contactObj.name}
              arrow
              placement="top"
            >
              <Avatar
                alt={contactObj.name}
                sx={{
                  width: 64,
                  height: 64,
                  bgcolor: contactObj.color,
                }}
                className="contact_avatar"
                src={contactObj.logo}
              />
            </BootstrapTooltip>
          </Item>
        ))}
      </div>
    </>
  );
};
export default Contact;
